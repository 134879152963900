export interface DesktopRedirectConfig {
  // If redirect present, redirect to this string
  redirect?: string;
}

/**
 * Path match map configuration
 */
export const exclusionPaths: string[] = [
  '/admin/billing',
  '/admin/users',
  '/admin/users/update',
  'notifications/list',
  'authenticate/*',
  '/',
  '/admin/templates/organizer',
];

export const pathMap: { [path: string]: DesktopRedirectConfig } = {
  '/authenticate/ml/:id': { redirect: '/magicLink/:id' },
  '/': { redirect: '/' },
  '/messages/list': { redirect: '/all_messages' },
  '/messages/details/:id': { redirect: '/inbox/:id' },

  '/files/new': { redirect: '/fileupload/new' },
  '/messages/new': { redirect: '/message/new' },
  '/accounts/list': { redirect: '/accounts' },

  '/contacts/list': { redirect: '/contacts' },

  '/tasks/list': { redirect: '/all_tasks' },
  '/tasks/details/:id': { redirect: '/task/detail/:id' },

  '/invoices/list': { redirect: '/billing/invoices' },
  '/invoices/details/:id': { redirect: '/billing/invoices/:id' },
  '/dashboard/home': { redirect: '/dashboard' },
  '/admin/templates/message': {
    redirect: '/templates/tab=message_template',
  },
  '/admin/templates/tasks': { redirect: '/templates?tab=task_template' },
  '/admin/templates/edocs': { redirect: '/templates?tab=edoc_template' },
  '/admin/preferences': { redirect: '/preferences' },
  '/admin/integrations': { redirect: '/integrations' },
  '/admin/sms': { redirect: '/sms-admin' },
};

export const pathMapRoutes = Object.keys(pathMap).map((path) => ({
  path,
}));

export const exclusionPathMapRoutes = exclusionPaths.map((path) => ({ path }));

/**
 * Defer rendering of these paths to prevent OTP consumption prior to redirect
 */
export const deferredPaths = ['/magicLink/:id', '/ml/:id'];
